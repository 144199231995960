import React, { useState, useEffect } from "react";
import { Backdrop, Box, CircularProgress, Divider, FormControl, IconButton, InputBase, InputLabel, Link, MenuItem, Paper, Popover, Select, Stack, Typography } from '@mui/material';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import styled from "@emotion/styled";
import ErrorBanner from '../features/errorBanner/errorBanner';
import {PdfViewer } from "../features/PdfViewer";
import '../features/PdfViewer.css';
import api from "../config/axiosConfigs";
import { config } from "../config/config";
import { getStringFromUrlKey, getObjectFromUrlKey } from '../features/localStorage/localStorageHelper';
import Tooltip from '@mui/material/Tooltip';
import './ChatSourcePage.css';

function ChatSourcePage() {

  const message = getStringFromUrlKey('message')
  const sources = getObjectFromUrlKey('source')
  const query = getStringFromUrlKey('query')
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [pdfViewerDisplay, setPdfViewerDisplay] = useState('none');
  const [error, setError] = useState(null);
  const [selectedPageSource, setSelectedPageSource] = useState(null);

  const maxWidth = 1400;

  const Item = styled(Paper)(({}) => ({
    backgroundColor: "#fff",
    border: "0 solid #e5e7eb",
    padding: 10,
    textAlign: "center",
  }));

  function get_pdf_url(document, page, start_line, end_line) {
    const data = {"document": document, "page": page, "start_line": start_line, "end_line": end_line}

    api.post("/monolith/getHighlightedDocument", data ).then((response) => {
      if (response.data.highlight_response.link === undefined) {
        setError("Failed to load highlighted PDF")
        setSelectedPdfUrl(null)
      } else {
        setSelectedPdfUrl(response.data.highlight_response.link);
        setSelectedPageSource(page);
      }
    }).catch((exception) => {
      setError("Failed to load highlighted PDF")
      setSelectedPdfUrl(null)
    });
  }

  const renderTextWithLineBreaks = (elements) => {
    return elements.map((element, index) => {
      // Check if the element is a string
      if (typeof element === 'string') {
        // Split the string by newlines and map to React fragments
        const lines = element.split('\n');
        return lines.map((line, lineIndex) => (
          <React.Fragment key={`line-${index}-${lineIndex}`}>
            {line}
            {lineIndex !== lines.length - 1 && <br />}
          </React.Fragment>
        ));
      } else if (element && element.$$typeof === Symbol.for('react.element')) {
        // If the element is a React element, render it directly
        return React.cloneElement(element, { key: `element-${index}` });
      } else {
        // If the element is neither a string nor a React element, return null or a placeholder
        return <React.Fragment key={`unknown-${index}`}></React.Fragment>;
      }
    });
  };

  function renderTextWithLinks(textWithLinks, sources) {
      const pattern = /(\[\d+\])/;
      const parts = textWithLinks.split(pattern).filter(Boolean);
      const sourcesMap = new Map(sources?.map(source => [source.id, source]));

      return parts.map(part => {
        const match = part.match(/\[(\d+)\]/);
        if (match) {
            const sourceId = match[1];
            const source = sourcesMap.get(sourceId);
            if (!source) {
              return "";
            } else {
              const { document, page, start_line, end_line } = source;
              return (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => get_pdf_url(document, page, start_line, end_line)}
                    color="primary"
                >
                    {part}
                </Link>
              );
            }
        } else {
            // return the plain text part.
            return part;
        }
      });
  }

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "100px" }}>
      {/* Loading Backdrop */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>
        {/* Main Content */}
        <Box sx={{
          marginTop: "10px",
          display: "flex",
          width: "100%",
          overflow: "hidden",
          alignContent: "center",
          alignItems: "flexStart",
          justifyContent: "center",
          overflow: 'visible'
        }}>

          {/* Left Side Content */}
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", p: 1, m: 1}}>
             {/* Question Component */}
             {sources && (<Stack direction="column" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: "left",
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <AutoAwesomeIcon size="large" color="primary" sx={{ paddingRight: 2 }}></AutoAwesomeIcon>
                <Typography><strong>Query</strong></Typography>
              </Box>
              <Typography variant="body2" align="left">
                {query}
              </Typography>
            </Stack>)}

            {/* Answer Component */}
            {sources && (<Stack direction="column" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: "left",
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <AutoAwesomeIcon size="large" color="primary" sx={{ paddingRight: 2 }}></AutoAwesomeIcon>
                <Typography><strong>Answer</strong></Typography>
              </Box>
              <Typography variant="body2" align="left">
                {message && renderTextWithLineBreaks(renderTextWithLinks(message, sources))}
              </Typography>
            </Stack>)}

            {/* Sources Component */}
            {sources && (<Stack direction="column" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: "left",
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <AutoAwesomeIcon color="primary" sx={{ paddingRight: 2 }}></AutoAwesomeIcon>
                <Typography><strong>Sources</strong></Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Box sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, minmax(0,1fr))",
                  gap: "20px"
                }}>
                  {sources && sources.map((citation, index) => (
                    <Item elevation={4} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
                        <div
                          className="circle-score-indicator"
                          style={{ backgroundColor: citation.score < config.THRESHOLD2_SemSim ? '#DA291C' : '#ff9898', 
                                   border: citation.score < config.THRESHOLD2_SemSim ? '1px solid black' : 'none',
                                   marginBottom: '5px'
                                }}
                        ></div>
                        <div
                          className="circle-score-indicator"
                          style={{ backgroundColor: citation.score < config.THRESHOLD1_SemSim && citation.score >= config.THRESHOLD2_SemSim ? '#ED8B00' : '#ffdb98',
                                   border: citation.score < config.THRESHOLD1_SemSim && citation.score >= config.THRESHOLD2_SemSim ? '1px solid black' : 'none',
                                   marginBottom: '5px'
                                }}
                        ></div>
                        <div 
                          className="circle-score-indicator"
                          style={{ backgroundColor: citation.score >= config.THRESHOLD1_SemSim ? '#43B02A' : '#98cb98', 
                                   border: citation.score >= config.THRESHOLD1_SemSim ? '1px solid black' : 'none',
                                   marginBottom: '5px'
                                }}
                        ></div>
                      </div>
                    <div> 
                    <Typography align="left"><strong>{citation.id}</strong> - {citation.document}, {citation.info_text}</Typography>
                    <Tooltip title={
                        <Typography align="left" color={"black"} style={{ marginRight: '5px' }}>
                        The cosine semantic similarity score reflects how closely your query matches the content of this source. 
                        </Typography>
                       } arrow placement="bottom">
                      <Typography align="left" color={"black"} style={{ marginRight: '5px' }}>
                          Score: {citation.score}
                        </Typography>
                    </Tooltip>
                    </div>
                  </Item>
                  ))}
                </Box>
                {sources && 
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px'  }}>
                      <div className="circle-score-indicator" style={{backgroundColor: '#43B02A', marginBottom: '0px'}}></div>
                      ≥ {config.THRESHOLD1_SemSim} &gt;
                      <div className="circle-score-indicator" style={{backgroundColor: '#ED8B00'}}></div>
                      ≥ {config.THRESHOLD2_SemSim} &gt;
                      <div className="circle-score-indicator" style={{backgroundColor: '#DA291C'}}></div>
                  </div>}
              </div>
            </Stack>)}
          </Stack>
          
        {/* Right Side Content */}
        {selectedPdfUrl && (
          <Box 
            sx={{ 
              p: 1, 
              m: 1,
              minWidth: "50%",
              overflow: "auto",
              display: `${pdfViewerDisplay}`              
              }}>
          <div className="pdfViewer" sx={{minHeight: "50%", overflow: "auto"}}>
            {selectedPdfUrl && <PdfViewer 
                                  pdfUrl={selectedPdfUrl}
                                  selectedPage={selectedPageSource} 
                                  setError={setError} 
                                  setPdfViewerDisplay={setPdfViewerDisplay} 
                                  setSelectedPdfUrl={setSelectedPdfUrl}
                                />}
          </div>
        </Box>)}
        </Box>
      <div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </div>
    </div>
  );
}

export default ChatSourcePage;