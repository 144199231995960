import { ThemeProvider } from '@emotion/react';
import ChatIcon from '@mui/icons-material/Chat';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PolylineIcon from '@mui/icons-material/Polyline';
import MenuIcon from '@mui/icons-material/Menu';
import AssistantIcon from '@mui/icons-material/Assistant';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Divider, SwipeableDrawer, Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import darkThemeDrawer from '../../theme/darkThemeDrawer';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { demo_mode, menu_title } from "../../config/config";

const drawerWidth = 300;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 20,
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  marginTop: "20px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

function NavigationDrawer(props) {
  const navigate = useNavigate();
  const handleDocumentManagement = () => navigate("/documentManagement");
  const handleSearch = () => navigate("/documentSearch")
  const handleChatbot = () => navigate("/chat", {state: { chatId: 'init'}});
  const handleChatbotFormAssistant = () => navigate("/chatAIChatAssistant");
  const handleFormFiller = () => navigate("/formFiller");
  const open = props.open

  return (
    <div>
      {open && (
        <ThemeProvider theme={darkThemeDrawer}>

          {/* Drawer */}
          <Drawer variant="permanent" open={open} anchor="left">

            {/* Drawer Header with Application Name and Drawer Close Button */}
            <DrawerHeader>
              <Box sx={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                alignContent: 'center',
                alignItems: 'center',
                px: 2.5,
                minHeight: 48,
                bgcolor: 'background.paper',
                flexDirection: 'row'
              }}>
                  <Typography><strong>{menu_title}</strong></Typography>
                <LightTooltip title="Close Sidebar" placement="right">
                  <IconButton aria-label="Open Sidebar" color="primary" onClick={props.handleDrawerClose} size="large">
                    <MenuIcon></MenuIcon>
                  </IconButton>
                </LightTooltip>
              </Box>
            </DrawerHeader>

            <Divider color="white"/>

            {/* Drawer Content */}
            <List>
              {renderMenuItem("Chatbot", handleChatbot, "Chatbot", <ChatIcon />, "Chat with your documents in an interactive conversation.")}
              {renderMenuItem("DocSearch", handleSearch, "DocSearch", <FindInPageIcon />, "Retrieve information from your documents.")}
              {renderMenuItem("DocManagement", handleDocumentManagement, "DocManagement", <UploadFileIcon />, "Manage your knowledge base - upload, view, and tag your documents.")}
              {(demo_mode === "FULL_APP" || demo_mode === "GPS_DEMO") && renderMenuItem("AIChatAssistant", handleChatbotFormAssistant, "AIChatAssistant", <AssistantIcon />, "Get assistance with finding forms, ask questions and evaluate your eligibility in a QuickCheck.")}
              {(demo_mode === "FULL_APP" || demo_mode === "GPS_DEMO") && renderMenuItem("FormFiller", handleFormFiller, "FormFiller", <EditNoteIcon />, "Fill out a form in natural language.")}
            </List>
          </Drawer>
        </ThemeProvider>
      )}
      
    </div>
  );

  function renderMenuItem(key, handleOnClick, text, menuIcon, tooltipText="") {
    return <ListItem key={key} disablePadding sx={{ display: "block" }}>
      <Tooltip title={tooltipText} placement="right">
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5
        }}
        onClick={handleOnClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center"
          }}
        >
          {menuIcon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
      </Tooltip>
    </ListItem>;
  }
}

export default NavigationDrawer;